import '../assets/css/Artikel.css';

import React, { useEffect, useState } from 'react'

import Navbar from '../components/Navbar';
import SEO from '../components/SEO';
import { artikelJSON } from '../helpers/dataJson';

export default function Artikel() {
  const [artikel, setArtikel] = useState([]);
  const [resultArray, setResultArray] = useState([]);
 useEffect(() => {
    // Menggunakan require.context untuk mengambil semua file dalam folder markdown-files
    const markdownFiles = require.context(`../markdown-files`, false, /\.md$/);
    const filesArray = markdownFiles.keys().map((fileName)=>{
      return {
        fileName: fileName.replace('./', ''), // Hapus './' dari nama file
      }
    })
      setArtikel(filesArray);
 },[])

useEffect(() => {
  setResultArray(artikelJSON?.map((item, indexJSON) => {
    const foundItem = artikel?.map((itemArtikel, index) =>  {
      if (item?.keyoword === itemArtikel?.fileName.replace(/\.md$/, '')) {
        return itemArtikel?.fileName;
      }
    });
    // Ambil fileName dari item yang ditemukan
    const fileName = foundItem?.length > 0 && foundItem?.find((item) => item !== undefined);
    let filterName;
    if(fileName !== false && fileName !== undefined){
      filterName = fileName?.toString().replace(/\.md$/, '')
    }
    return {
      id: item?.id,
      title: item?.title,
      image: item?.image,
      content: item?.content,
      fileName: filterName
    }
  }));
},[artikel]);
  return (
    <>
    <SEO description={"layanan pengembangan website, jasa pembuatan website, layanan perancangan website, jasa pembuatan web, layanan pembuatan web"} type={"website"}/>
    <Navbar />
       <h2 className='heading'>Artikel</h2>
    <div className='card-header'>
      {resultArray?.map((item, index)=>{
        return (
        <div className='card-child' key={index}>
          <h3 className='headline'>
            {item?.title}
          </h3>
          <div>
            <img className='image'
              src={item?.image}
              height="10px"
              width="20px"
              alt={item?.image.replace(/.*\/(.*)\..*\..*/, '$1').toString()}/>
          </div>
          <p className='paragraph-subheading'>
            {item?.content}
          </p>
          <div className='link-button'>
            <a key={index} href={`/artikel/${item.fileName}`} className='link'>
            Info Selengkapnya
            </a>
          </div>
        </div>
        )
      })}
    </div>
    </>
  )
}
