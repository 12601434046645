import Jasa_Pembuatan_Web_Jawa_Barat from "../assets/img/porto/Jasa Pembuatan Web Jawa Barat.jpg"
import Jasa_Pembuatan_Web_Kota_Depok from "../assets/img/porto/Jasa Pembuatan Web Kota Depok.jpg"
import Jasa_Pembuatan_Web_Tapos from "../assets/img/porto/Jasa Pembuatan Web Tapos.jpg"
import Jasa_Pembuatan_Website_Jawa_Barat from "../assets/img/porto/Jasa Pembuatan Website Jawa Barat.jpg"
import Jasa_Pembuatan_Website_Kota_Depok from "../assets/img/porto/Jasa Pembuatan Website Kota Depok.jpg"
import Jasa_Pembuatan_Website_Tapos from "../assets/img/porto/Jasa Pembuatan Website Tapos.jpg"
import Jasa_Pengembangan_Web_Jawa_Barat from "../assets/img/porto/Jasa Pengembangan Web Jawa Barat.jpg"
import Jasa_Pengembangan_Web_Kota_Depok from "../assets/img/porto/Jasa Pengembangan Web Kota Depok.jpg"
import Jasa_Pengembangan_Web_Tapos from "../assets/img/porto/Jasa Pengembangan Web Tapos.jpg"
import Jasa_Web_Development_Jawa_Barat from "../assets/img/partners/Jasa Web Development Jawa Barat.svg"
import Jasa_Web_Development_Kota_Depok from "../assets/img/partners/Jasa Web Development Kota Depok.svg"
import Jasa_Web_Development_Tapos from "../assets/img/partners/Jasa Web Development Tapos.svg"
import Layanan_Pembuatan_Web_Jawa_Barat from "../assets/img/porto/Layanan Pembuatan Web Jawa Barat.jpg"
import Layanan_Pembuatan_Web_Kota_Depok from "../assets/img/porto/Layanan Pembuatan Web Kota Depok.jpg"
import Layanan_Pembuatan_Web_Tapos from "../assets/img/porto/Layanan Pembuatan Web Tapos.jpg"
import Layanan_Pengembangan_Web_Jawa_Barat from "../assets/img/porto/Layanan Pengembangan Web Jawa Barat.jpg"
import Layanan_Pengembangan_Web_Kota_Depok from "../assets/img/porto/Layanan Pengembangan Web Kota Depok.jpg"
import Layanan_Pengembangan_Web_Tapos from "../assets/img/porto/Layanan Pengembangan Web Tapos.jpg"
import Layanan_Pengembangan_Website_Jawa_Barat from "../assets/img/porto/Layanan Pengembangan Website Jawa Barat.jpg"
import Layanan_Pengembangan_Website_Kota_Depok from "../assets/img/Layanan Pengembangan Website Kota Depok.png"
import Layanan_Pengembangan_Website_Tapos from "../assets/img/Layanan Pengembangan Website Tapos.png"
import Layanan_Web_Development_Jawa_Barat from "../assets/img/partners/Layanan Web Development Jawa Barat.svg"
import Layanan_Web_Development_Kota_Depok from "../assets/img/partners/Layanan Web Development Kota Depok.svg"
import Navbar from "../components/Navbar";
import React from "react";
import SEO from "../components/SEO";
import { useState } from 'react';

export default function Home() {
    const [activeHero, setActiveHero] = useState(1);
    const [subject, setSubject] = useState("");

    const active = (heroNumber) => {
        document.querySelectorAll('.hero').forEach(hero => {
            hero.classList.remove('hero-active');
        });
        document.querySelector(`.hero-${heroNumber}`).classList.add('hero-active');
        setActiveHero(heroNumber);
    }

    const sendEmail = (e) => {
        e.preventDefault();
        window.location.href = `mailto:genbox22@gmail.com?subject=Request Jasa / Layanan PT. Generasi Digital Nasional"&body="Hallo Genbox Universe" ${subject}`    
    }

    return (
        <>
        <SEO title="Layanan Pengembangan Web Kota Depok" description="Layanan Pembuatan Web Jawa Barat, Layanan Pembuatan Aplikasi Android Jawa Barat, Layanan Pembuatan Perangkat IoT Tapos, Layanan Pengembangan Web Kota Depok, Layanan Perancangan Web Tapos, Jasa Pembuatan Website, Jasa Pembuatan Web" type="website" url="https://genbox.id"/>
        <header>
            <Navbar />
            <div className="container pt-3 position-relative">
                <img src={Layanan_Pengembangan_Website_Kota_Depok} alt="Layanan_Pengembangan_Website_Kota_Depok" className="ornament-left" />
                <img src={Layanan_Pengembangan_Website_Tapos} alt="Layanan_Pengembangan_Website_Tapos" className="ornament-right" />
                <h1 className="text-center fw-light mb-4">
                    <b className="fw-bold font-grad">Gen</b>eration Out of The <b className="fw-bold font-grad">Box</b>.
                </h1>
                <div className="container-x">
                    <div className="d-flex gap-2">
                        <div onClick={() => active(1)} className={`bg-secondary rounded hero hero-1 ${activeHero === 1 ? 'hero-active' : ''}`}>
                            <div className="content">
                                <h3 className="tshadow">Application<br />development & branding</h3>
                            </div>
                        </div>
                        <div onClick={() => active(2)} className={`bg-secondary rounded hero hero-2 ${activeHero === 3 ? 'hero-two' : ''} ${activeHero === 2 ? 'hero-active' : ''}`}>
                            <div className="content">
                                <h3 className="tshadow">Academy<br />and Human resource</h3>
                            </div>
                        </div>
                        <div onClick={() => active(3)} className={`bg-secondary rounded hero hero-3 ${activeHero === 3 ? 'hero-active' : ''}`}>
                            <div className="content">
                                <h3 className="tshadow">AI research and<br />IoT implementation</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <p className="text-center text-white">Go build your own with us, under the auspices of <b>PT Generasi Digital Nasional</b></p>
                <div className="d-flex justify-content-center gap-2">
                    <a href="https://wa.me/6285174072416" aria-label="Jasa Pembuatan Aplikasi Android Jawa Barat" className="rounded px-3 py-2 text-decoration-none btn-main">Let's schedule our time</a>
                    <a href="https://drive.google.com/file/d/1FsiSfXFKma7StXWivjs1Z_iAN-Yp7HJs/view?usp=sharing" className="rounded px-3 py-2 text-decoration-none btn-outline-main" aria-label="Jasa Pembuatan Aplikasi Android Kota Depok">Company Profile</a>
                </div>
            </div>
        </header>
        <div className="banner">
            <div className="d-flex justify-content-center flex-wrap gap-5 text-white">
                <div className="text-center">
                    <h1 className="fs-1 fw-light">3+</h1>
                    <small>Product</small>
                </div>
                <div className="text-center">
                    <h1 className="fs-1 fw-light">20+</h1>
                    <small>Project</small>
                </div>
                <div className="text-center">
                    <h1 className="fs-1 fw-light">100+</h1>
                    <small>Agency</small>
                </div>
            </div>
            <hr className="w-25 m-auto my-4" />
            <div>
                <div className="container text-light text-center"><p className="fw-light"><b className="fw-bold">Branding and manage</b> your business with our team who is fully <b className="fw-bold">committed</b> to your <b className="fw-bold">brand</b></p></div>
            </div>  
        </div>          
        <h3 className="fw-light text-center mt-5 mb-4">
            Our services.
        </h3>
        <div className="container">
            <div className="row mt-5">
                <div className="col-sm-6 col-md-4">
                    <div className="card mb-3 px-3 py-5 shadow-lg">
                        <h4 className="font-grad">Website Application </h4>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">Start from 500.000 IDR</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">Free 2x Maintenance</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">guarantee 1 year</div>
                        </div>
                    </div>
                </div>     
                <div className="col-sm-6 col-md-4">
                    <div className="card mb-3 px-3 py-5 shadow-lg">
                        <h4 className="font-grad">Mobile Application </h4>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">Start from 1.500.000 IDR</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">Free up to playstore</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">guarantee 1 year</div>
                        </div>
                    </div>
                </div>     
                <div className="col-sm-6 col-md-4">
                    <div className="card mb-3 px-3 py-5 shadow-lg">
                        <h4 className="font-grad">Game Development</h4>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div  className="fw-light">Start from 3.500.000 IDR</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div  className="fw-light">Asset not include</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div  className="fw-light">guarantee 1 year</div>
                        </div>
                    </div>
                </div> 
            </div>    
            <div className="row">           
                <div className="col-sm-6 col-md-4">
                    <div className="card mb-3 px-3 py-5 shadow-lg">
                        <h4 className="font-grad">Internet of Things Development</h4>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">Start from 1.000.000 IDR</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">Module not include</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">guarantee 1 year</div>
                        </div>
                    </div>
                </div>             
                <div className="col-sm-6 col-md-4">
                    <div className="card mb-3 px-3 py-5 shadow-lg">
                        <h4 className="font-grad">Blockchain / Smart Contract</h4>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">Start from 8.600.000 IDR</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">Free Source code</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">guarantee 1 year</div>
                        </div>
                    </div>
                </div>             
                <div className="col-sm-6 col-md-4">
                    <div className="card mb-3 px-3 py-5 shadow-lg">
                        <h4 className="font-grad">Artificial intelligence</h4>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">Start from 3.500.000 IDR</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">Free Source code</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">guarantee 1 year</div>
                        </div>
                    </div>
                </div>   
            </div>    
        </div>               
        <h3 className="fw-light text-center mt-5 mb-4">
            Our Partner.
        </h3>
        <div className="pb-5">
            {/* <h6 className="text-center text-gray">partners development</h6> */}
            <div className="d-flex justify-content-center flex-wrap w-100 gap-5 py-3 align-items-center">
                <img src={Layanan_Web_Development_Jawa_Barat} height="40px" className="partner" alt="Layanan_Web_Development_Jawa_Barat" />
                <img src={Layanan_Web_Development_Kota_Depok} height="40px" className="partner" alt="Layanan_Web_Development_Kota_Depok" />
                <img src={Jasa_Web_Development_Tapos} height="40px" className="partner" alt="Jasa_Web_Development_Tapos" />
                <img src={Jasa_Web_Development_Kota_Depok} height="20px" className="partner" alt="Jasa_Web_Development_Kota_Depok" />
                <img src={Jasa_Web_Development_Jawa_Barat} height="40px" className="partner" alt="Jasa_Web_Development_Jawa_Barat" />
            </div>
        </div>
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-6">
                    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">   
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="6" aria-label="Slide 7"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="7" aria-label="Slide 8"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="8" aria-label="Slide 9"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="9" aria-label="Slide 10"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="10" aria-label="Slide 11"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="11" aria-label="Slide 12"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="12" aria-label="Slide 13"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="13" aria-label="Slide 14"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="14" aria-label="Slide 15"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="15" aria-label="Slide 16"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item rounded position-relative active">
                            <img src={Layanan_Pengembangan_Web_Tapos} className="d-block w-100 rounded" alt="Layanan_Pengembangan_Web_Tapos" />
                            <div className="carousel-caption d-none d-md-block bg-porto">
                                <h5>Magicrylic App</h5>
                                <p className="fw-light">E-cataloge App 2023-2024</p>
                            </div>
                            </div>
                            <div className="carousel-item rounded position-relative">
                            <img src={Layanan_Pengembangan_Website_Jawa_Barat} className="d-block w-100 rounded" alt="Layanan_Pengembangan_Website_Jawa_Barat" />
                            <div className="carousel-caption d-none d-md-block bg-porto">
                                <h5>Camakapu App</h5>
                                <p className="fw-light">Goverment NTT 2024</p>
                            </div>
                            </div>
                            <div className="carousel-item rounded position-relative">
                            <img src={Jasa_Pembuatan_Web_Jawa_Barat} className="d-block w-100 rounded" alt="Jasa_Pembuatan_Web_Jawa_Barat" />
                            <div className="carousel-caption d-none d-md-block bg-porto">
                                <h5>Siap dan Sitihanum</h5>
                                <p className="fw-light">Goverment Kendari 2023-2024</p>
                            </div>
                            </div>
                            <div className="carousel-item rounded position-relative">
                            <img src={Jasa_Pembuatan_Web_Kota_Depok} className="d-block w-100 rounded" alt="Jasa_Pembuatan_Web_Kota_Depok" />
                            <div className="carousel-caption d-none d-md-block bg-porto">
                                <h5>Sipeta guru</h5>
                                <p className="fw-light">Goverment Kendari 2023-2024</p>
                            </div>
                            </div>
                            <div className="carousel-item rounded position-relative">
                            <img src={Jasa_Pembuatan_Web_Tapos} className="d-block w-100 rounded" alt="Jasa_Pembuatan_Web_Tapos" />
                            <div className="carousel-caption d-none d-md-block bg-porto">
                                <h5>Sieva APBD</h5>
                                <p className="fw-light">Goverment Kendari 2023-2024</p>
                            </div>
                            </div>
                            <div className="carousel-item rounded position-relative">
                            <img src={Jasa_Pembuatan_Website_Jawa_Barat} className="d-block w-100 rounded" alt="Jasa_Pembuatan_Website_Jawa_Barat" />
                            <div className="carousel-caption d-none d-md-block bg-porto">
                                <h5>PDAM Bontang</h5>
                                <p className="fw-light">PDAM Bontang 2021-2024</p>
                            </div>
                            </div>
                            <div className="carousel-item rounded position-relative">
                            <img src={Jasa_Pembuatan_Website_Kota_Depok} className="d-block w-100 rounded" alt="Jasa_Pembuatan_Website_Kota_Depok" />
                            <div className="carousel-caption d-none d-md-block bg-porto">
                                <h5>CRUD Studio</h5>
                                <p className="fw-light">Software House 2023</p>
                            </div>
                            </div>
                            <div className="carousel-item rounded position-relative">
                            <img src={Jasa_Pembuatan_Website_Tapos} className="d-block w-100 rounded" alt="Jasa_Pembuatan_Website_Tapos" />
                            <div className="carousel-caption d-none d-md-block bg-porto">
                                <h5>Mandiri Inhealth</h5>
                                <p className="fw-light">Subkon Pollux 2023</p>
                            </div>
                            </div>
                            <div className="carousel-item rounded position-relative">
                            <img src={Jasa_Pengembangan_Web_Jawa_Barat} className="d-block w-100 rounded" alt="Jasa_Pengembangan_Web_Jawa_Barat" />
                            <div className="carousel-caption d-none d-md-block bg-porto">
                                <h5>Carbon Travel</h5>
                                <p className="fw-light">Subkon Entercode 2023</p>
                            </div>
                            </div>
                            <div className="carousel-item rounded position-relative">
                            <img src={Jasa_Pengembangan_Web_Kota_Depok} className="d-block w-100 rounded" alt="Jasa_Pengembangan_Web_Kota_Depok" />
                            <div className="carousel-caption d-none d-md-block bg-porto">
                                <h5>CGS CIMB</h5>
                                <p className="fw-light">Subkon Pollux 2023</p>
                            </div>
                            </div>
                            <div className="carousel-item rounded position-relative">
                            <img src={Jasa_Pengembangan_Web_Tapos} className="d-block w-100 rounded" alt="porto" />
                            <div className="carousel-caption d-none d-md-block bg-porto">
                                <h5>Genbox Academy</h5>
                                <p className="fw-light">Our Academy web</p>
                            </div>
                            </div>
                            <div className="carousel-item rounded position-relative">
                            <img src={Layanan_Pembuatan_Web_Jawa_Barat} className="d-block w-100 rounded" alt="Layanan_Pembuatan_Web_Jawa_Barat" />
                            <div className="carousel-caption d-none d-md-block bg-porto">
                                <h5>CEO Web</h5>
                                <p className="fw-light">Our CEO web</p>
                            </div>
                            </div>
                            <div className="carousel-item rounded position-relative">
                            <img src={Layanan_Pembuatan_Web_Tapos} className="d-block w-100 rounded" alt="Layanan_Pembuatan_Web_Tapos" />
                            <div className="carousel-caption d-none d-md-block bg-porto">
                                <h5>Genbox Academy</h5>
                                <p className="fw-light">Our Payment web</p>
                            </div>
                            </div>
                            <div className="carousel-item rounded position-relative">
                            <img src={Layanan_Pembuatan_Web_Kota_Depok} className="d-block w-100 rounded" alt="Layanan_Pembuatan_Web_Kota_Depok" />
                            <div className="carousel-caption d-none d-md-block bg-porto">
                                <h5>Genbox Enterprise</h5>
                                <p className="fw-light">Our web</p>
                            </div>
                            </div>
                            <div className="carousel-item rounded position-relative">
                            <img src={Layanan_Pengembangan_Web_Jawa_Barat} className="d-block w-100 rounded" alt="Layanan_Pengembangan_Web_Jawa_Barat" />
                            <div className="carousel-caption d-none d-md-block bg-porto">
                                <h5>Slicing Design</h5>
                                <p className="fw-light">Subkon CRUD Studio</p>
                            </div>
                            </div>
                            <div className="carousel-item rounded position-relative">
                            <img src={Layanan_Pengembangan_Web_Kota_Depok} className="d-block w-100 rounded" alt="Layanan_Pengembangan_Web_Kota_Depok" />
                            <div className="carousel-caption d-none d-md-block bg-porto">
                                <h5>Gramedia Affiliate</h5>
                                <p className="fw-light">Store app 2022</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-3 col-md-6 d-flex flex-column justify-content-end ">
                    <h1 className="text-start mb-4 fs-1">
                        Our Portfolio.
                    </h1>
                    <p className="fw-light mb-5">The projects that our company works on include full contracts, subcontracts, maintenance, adding several features, and also the owner's personal projects.</p>
                    <a href="https://github.com/risanussy" className="btn text-white fs-5 m-0 p-0 text-start fw-light" aria-label="Jasa Pembuatan Aplikasi Android Tapos">our ceo github <i className="fa-solid fa-arrow-up-right-from-square ms-2" ></i></a>
                </div>
            </div>
        </div>
        <br />
        <br />
        <br />
        <div className="w-100 bg-resource pb-5">
            <div className="container py-5">
                <h1 className="text-center">
                    We also have profesional resource<br/>for yours business or team
                </h1>
                <div className="d-flex justify-content-center pt-5 gap-3">
                <a href="https://wa.me/6285174072416" className="rounded px-3 py-2 text-decoration-none btn-main" aria-label="Layanan Pembuatan Aplikasi Android Tapos">Chat us</a>
                <a href="https://www.instagram.com/genbox.academy" className="rounded px-3 py-2 text-decoration-none btn-outline-main" aria-label="Layanan Pembuatan Aplikasi Android Jawa Barat">our activity</a>
                </div>
                <img src="https://cdn.prod.website-files.com/637359c81e22b715cec245ad/662810ffa88d9c774f996ae0_green-earth.webp" alt="earth" className="w-100" style={{ mixBlendMode: 'lighten' }} />
            </div>
        </div>
        <div className="w-100 bg-grad py-3">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h1>Let's schedule our time</h1>
                    </div>
                    <div className="col-md-6">
                        <form className="h-100 d-flex align-items-center gap-3" onSubmit={sendEmail}>
                            <input type="text" onInput={(e) => setSubject(e.target.value)} placeholder="subject email" className="form-control" />
                            <button className="btn btn-outline-light">Send</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <footer className="bg-footer py-3">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 mb-3">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15859.106779456873!2d106.8922225!3d-6.4227311!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ebff1177fb53%3A0x56e21f10fb9d1fe6!2sHybrid%20Office%20-%20Virtual%20Office%20Depok!5e0!3m2!1sid!2sid!4v1723240872303!5m2!1sid!2sid" title="Layanan Pembuatan Perangkat IoT Tapos" width="100%" className="rounded" height="180" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className="col-md-4 mb-3">
                        <small className="fw-light">PT Generasi Digital Nasional</small>
                        <h1 className="font-grad">Genbox Enterprise</h1>
                        <br />
                        <p className="fw-light">Developer Technology Technology Indonesian. Invest Digital Product for best future</p>
                    </div>
                    <div className="col-md-4 mb-3">
                        <h3 className="fw-light mb-3">Contact</h3>
                        <div className="d-flex align-items-center mb-2">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">hello@genbox.id</div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">genbox22@gmail.com</div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                            <div className="fw-light">+62 851 7407 2416</div>
                        </div>
                    </div>
                </div>
            </div>
            <center><i className="fw-light d-inline-block">&copy; copyright genbox</i></center>
        </footer>
        </>
    );
}
