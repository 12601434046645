import '../assets/css/App.css';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Artikel from './Artikel';
import DetailArtikel from './DetailArtikel';
import Home from "./Home"

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/artikel' element={<Artikel/>} />      
        <Route path='/artikel/:id' element={<DetailArtikel/>} />
      </Routes>
    </Router>
  );
}

export default App;
