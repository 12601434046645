import '../assets/css/Artikel.css';

import React, {useEffect, useState} from 'react'

import Blogs from '../components/Blogs';
import SEO from "../components/SEO";
import {artikelJSON} from '../helpers/dataJson';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function DetailArtikel() {
 const { id } = useParams();
 const [artikel, setArtikel] = useState([]);
 useEffect(() => {
   artikelJSON?.map((item) => {
   if (item?.keyoword === id) {
     return setArtikel(item)
    //  return item
   }
 },[artikelJSON])
 })
 const navigate = useNavigate();
  return (
    <>
    <SEO description={artikel?.description} title={artikel?.keyoword?.replace(/_/g, " ")} type={"website"} url={`https://www.genbox.id/artikel/${id}`}/>
    <div className='header'>
        <button type='button' onClick={() =>  navigate("/artikel")} className='btn btn-primary'>Back Home</button>
    </div>
    {/* title data json include id */}
    <div className='body-content'>
      <div>
        {id &&  <Blogs fileName={id}/>}
      </div>
    </div>
    </>
  )
}
