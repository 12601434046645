import Jasa_Pembuatan_Website from '../assets/img/porto/Jasa_Pembutan_Website.jpg';
import Layanan_Pembuatan_Web from '../assets/img/Layanan_Pembuatan_Web.svg'
import Layanan_Pengembangan_Web from "../assets/img/Layanan Perancangan Web Tapos.jpeg"
import Layanan_Pengembangan_Website_Depok from "../assets/img/partners/Layanan_Pengembangan_Website_Depok.webp"
import Layanan_Perancangan_Website from "../assets/img/Layanan_Perancangan_Website.jpeg"

export const artikelJSON = [
  {
    id: 1,
    title: "Layanan Pengembangan Website Kota Depok: Apa Solusi Bisnis Digital?",
    image: Layanan_Pengembangan_Website_Depok,
    content: "Di era digital saat ini, website menjadi perhatian untuk mengembangkan bisnis dengan menggunakan layanan pengembangan website javascript profesional kota depok.",
    keyoword: "layanan_pengembangan_website",
    description: "layanan pengembangan website kota depok,layanan pengembangan website tapos,layanan pengembangan website jawa barat,layanan pengembangan website javascript,layanan pengembangan website gratis,layanan pengembangan website terbaik,layanan pengembangan website profesional,layanan pengembangan website javascript profesional kota depok"
  },
  {
    id: 2,
    title: "Tips Memilih Jasa Pembuatan Website Yang Profesional?",
    image: Jasa_Pembuatan_Website,
    content: "Memilih jasa pembuatan website yang profesional merupakan langkah penting dalam mengembangkan binis anda.",
    keyoword: "jasa_pembuatan_website",
    description: "jasa pembuatan website,jasa pembuatan website profesional tapos,jasa pembuatan website kota depok,jasa pembuatan website tapos,jasa pembuatan website javascript kota depok"
  },
  {
    id: 3,
    title: "Mengapa React Lebih Banyak Digunakan Untuk Layanan Perancangan Website Modern ?",
    image: Layanan_Perancangan_Website,
    content: "Reactjs merupakan framework javascript populer. Lebih banyak memilih reactjs karena dapat meningkatkan konversi dan juga meningkatkan branding bisnis.",
    keyoword: "layanan_perancangan_website",
    description: "layanan perancangan website modern,layanan perancangan website javascript jawa barat,layanan perancangan website kota depok,layanan perancangan website company profile,layanan perancangan website tapos"
  },
  {
    id: 4,
    title: "Siapa Framework Terpopuler ReactJS vs VueJS Untuk Layanan Perancangan Web Tapos?",
    image: Layanan_Pengembangan_Web,
    content: "Reactjs dan Vuejs merupakan framework javascript populer dalam layanan perancangan web tapos. Keduanya sudah digunakan oleh ribuan pengembagan diseluruh dunia.",
    keyoword: "layanan_perancangan_web_tapos",
    description: "layanan perancangan web tapos,layanan perancangan web jawa barat,layanan perancangan web kota depok,layanan perancangan web profesional,layanan perancangan web javascript,layanan perancangan web tapos" 
  },
  {
    id: 5,
    title: "Apa itu Genbox?",
    image: Layanan_Pembuatan_Web,
    content: "Genbox adalah brand dari PT.Generasi Digital Nasional yang menyediakan layanan pembuatan website kota depok, layanan pembuatan aplikasi android kota depok, dan lain sebagainya.",
    keyoword: "layanan_pembuatan_website",
    description: "layanan pembuatan website kota depok,layanan pembuatan aplikasi android kota depok,layanan pembuatan Internet Of Things (IoT) kota depok, layanan pembuatan game development kota depok"
  }
]