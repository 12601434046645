import Layanan_Pembuatan_Web from '../assets/img/Layanan_Pembuatan_Web.svg'
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Navbar() {
      const navigate = useNavigate();
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark border border-0 border-bottom border-dark">
                <div className="container">
                    <h1 className='navbar-brand fs-6 d-flex align-items-center' onClick={() => navigate('/')}>
                        <img src={Layanan_Pembuatan_Web} className="me-2" alt="Layanan_Pembuatan_Web" height={'28px'} />
                        Genbox <span className='fw-light'>Enterprise</span>
                    </h1>
                </div>
            </nav>
            <nav className="navbar navbar-dark navbar-expand-lg">
                <div className="container">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="https://genbox.academy" aria-label="Jasa Pembuatan Aplikasi Android Tapos">Academy</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" aria-label="Jasa Pengembangan IoT Jawa Barat" href="/artikel">Article</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href={`mailto:genbox22@gmail.com?subject="Request Jasa / Layanan PT. Generasi Digital Nasional"&body="Hallo Genbox Universe"`} aria-label="Jasa Pengembangan IoT Tapos">
                                    <i className="fa-regular fa-envelope me-2"></i>
                                   genbox22@gmail.com
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}
