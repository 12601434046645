import '../assets/css/App.css';

import React, {useEffect, useState} from 'react'

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

export default function Blogs({fileName}) {
    const [content, setContent] = useState('');
    useEffect(() => {
        import(`../markdown-files/${fileName}.md`)
            .then(res => {
             // Fetch the content of the file
            fetch(res.default)
            .then((response)=> response.text())
            .then((text) =>{  
                setContent(text);
            })
            .catch((error) => console.log("error catching",error))
        }).catch((error) => {
            console.log("error File Tidak Di Temukan",error)})
    }, [fileName]);
  return (
  <div className="markdown-body">
    <ReactMarkdown children={content} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} />
  </div>
  )
}
